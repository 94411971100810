import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useLocation from 'react-use/lib/useLocation'
import throttle from 'lodash/throttle'
import './index.css'
import { ContentfulEntry } from '../../components/Critique/ContentfulEntry'
import { CritiqueResultsBanner } from './CritiqueResultsBanner'
import {
  CritiqueContextProvider,
  CritiqueDataContext,
  CritiqueSelect,
  ICritiqueProfile,
} from './useCritiqueCopy'
import { CritiquePageProvider } from './CritiqueProvider'
import { useCritiqueStyles } from './styles'
import {
  useMixpanelCTAClickTracking,
  useMpEventTrackOn,
} from '@talentinc/gatsby-theme-ecom/utils/mixpanel'
import {
  MixpanelEventTypes,
  MixpanelEvents,
} from '@talentinc/gatsby-theme-ecom/types/mixpanel'
import { updateHrefs } from '@talentinc/gatsby-theme-ecom/utils/dom'
import {
  SessionAPIResponse,
  useSessionQuery,
} from '@talentinc/gatsby-theme-ecom/components/Providers/UserSession'
import { useFETelemetry } from '@talentinc/gatsby-theme-ecom/hooks/useTelemetry'

type WayPoint = 25 | 50 | 75 | 100

const SCROLL_WAY_POINTS: WayPoint[] = [25, 50, 75, 100]

const STICKY_SIDEBAR_V3_HEIGHT = 700
const STICKY_SIDEBAR_V3_MARGIN = 200
const STICKY_SIDEBAR_V3_OFFSET = 150

export type TemplateProps = {
  rows: any[]
  footer?: any[]
  variant?: string
}

const updateLinksRIO = (data: SessionAPIResponse | undefined) => {
  const isRIOSession = data?.partnerCoBrand?.name === 'TopResume + Resume.io'
  if (isRIOSession)
    updateHrefs(
      '../resume-writing',
      'https://resumeio.topresume.com/resume-writing?dt=TRCIO2023 '
    )
}

const updateLinksV3LeadBands = (pathname: string) => {
  const linkURL = {
    '/new/resumecritiqueview-v3-1': 'resume-writing-total-job-search-2',
    '/new/resumecritiqueview-v3': 'resume-writing-total-job-search-3',
  }[pathname] as string

  linkURL && updateHrefs('../new/resume-writing-total-job-search', linkURL)
  linkURL &&
    updateHrefs(
      '../new/resume-writing-total-job-search?tab=job_search',
      linkURL + '?tab=job_search'
    )
  linkURL && updateHrefs('/resume-writing', linkURL)
}

const fadeFixSidebar = (fix: boolean) => {
  const pageContainer = document.querySelector('.critique-page')
  pageContainer?.classList[fix ? 'add' : 'remove']('fix-sidebar')

  const critiqueSidebar = document.querySelector('.critique-sidebar')

  const nextStepsSection = document.querySelector(
    '#critique-section-nextsteps'
  ) as any

  critiqueSidebar?.setAttribute(
    'style',
    `display: flex; flex-direction: column; gap: 24px; height: unset; max-height: ${
      nextStepsSection?.offsetTop - STICKY_SIDEBAR_V3_OFFSET
    }px`
  )

  if (fix) {
    const sidebar = document.querySelector('.next-steps-container')

    sidebar?.setAttribute('style', 'opacity: 0; transition: opacity 0s')
    setTimeout(() => {
      sidebar?.setAttribute('style', 'opacity: 1; transition: opacity 0.3s')
    }, 1)
  }
}

const CritiqueTemplateContent: FC<{
  rows: TemplateProps['rows']
  footer: TemplateProps['footer']
  variant: string
}> = ({ rows, footer, variant }) => {
  const critiqueStyles = useCritiqueStyles()
  const { classes, cx } = useStyles()
  const { pathname } = useLocation()
  const {
    active: [, activeCritique, { isPerfect }],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }
  const { data } = useSessionQuery()

  const isV3 = variant === 'v3'

  useEffect(() => {
    updateLinksRIO(data)
  }, [data, activeCritique])

  useEffect(() => {
    isV3 && pathname && updateLinksV3LeadBands(pathname)
  }, [isV3, pathname, activeCritique])

  return (
    <div
      className={cx(classes.content, {
        'perfect-critique': isPerfect,
      })}
    >
      <Container
        className={cx(classes.entries, critiqueStyles.classes.rowsContainer)}
        disableGutters
      >
        {rows &&
          rows?.map((row) => (
            <ContentfulEntry key={row.contentful_id} {...row} />
          ))}
      </Container>
      {footer &&
        footer?.map((row) => (
          <ContentfulEntry key={row.contentful_id} {...row} />
        ))}
      <CritiqueResultsBanner />
    </div>
  )
}

export const Template: FC<TemplateProps> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const { rows, footer, variant = 'v1' } = props
  const { classes, cx } = useStyles()
  const firedScrollEvents = useRef<Record<number, boolean>>({})
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const critiqueEventTrackOn = useMpEventTrackOn(
    MixpanelEventTypes.CritiqueEvent
  )
  const telemetry = useFETelemetry()

  const [fixSidebar, setFixSidebar] = useState(false)

  const isV2 = variant === 'v2'
  const isV3 = variant === 'v3'

  useEffect(() => {
    setTimeout(() => {
      mainRef.current?.classList.add('loaded')
      telemetry.track({
        event: 'critique_viewed',
        properties: { event_type: 'critique_event' },
      })
    }, 250)
    // eslint-disable-next-line
  }, [])

  const sidebarHeight = useMemo(() => {
    try {
      const nextSteps = document.querySelector(
        '.next-steps-container'
      )?.clientHeight
      const storyItemsElList = document.querySelectorAll(
        '[data-testid="section-success-stories"]'
      )
      const storyItemsHeight = Array.from(storyItemsElList).reduce(
        (acc, el) => acc + el.clientHeight,
        0
      )

      return (
        (storyItemsHeight + (nextSteps || 0) || STICKY_SIDEBAR_V3_HEIGHT) * 2 -
        STICKY_SIDEBAR_V3_MARGIN
      )
    } catch (e) {
      return STICKY_SIDEBAR_V3_HEIGHT * 2 - STICKY_SIDEBAR_V3_MARGIN
    }
  }, [])

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollPercent =
          (window.scrollY /
            (window.document.body.offsetHeight - window.innerHeight)) *
          100

        if (isV3) setFixSidebar(window.scrollY > sidebarHeight)

        SCROLL_WAY_POINTS.forEach(function (waypoint) {
          if (
            scrollPercent >= waypoint - 1 &&
            scrollPercent <= waypoint + 1 &&
            !firedScrollEvents.current[waypoint]
          ) {
            firedScrollEvents.current[waypoint] = true

            handleMpCTAClickTracking(null, '', {
              eventName: MixpanelEvents[`CritiqueScroll${waypoint}`],
              trackingData: {
                event_type: MixpanelEventTypes.CritiqueEvent,
              },
              trackOn: critiqueEventTrackOn,
            })
          }
        })
      }, 100),
    [critiqueEventTrackOn, handleMpCTAClickTracking, isV3, sidebarHeight]
  )

  useEffect(() => {
    if (isV3) fadeFixSidebar(fixSidebar)
  }, [fixSidebar, isV3])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    handleScroll()
  }, [handleScroll])

  return (
    <CritiqueContextProvider>
      <CritiquePageProvider variant={variant}>
        <Box
          ref={mainRef}
          className={cx(
            classes.root,
            'critique-page',
            isV2 && 'v2',
            isV3 && 'v3'
          )}
          component="main"
        >
          <CritiqueSelect />
          <CritiqueTemplateContent
            rows={rows}
            footer={footer}
            variant={variant}
          />
        </Box>
      </CritiquePageProvider>
    </CritiqueContextProvider>
  )
}

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
  },

  entries: {
    [theme.breakpoints.down(426)]: {
      padding: '0 24px',
    },
  },
}))
