import React, { useMemo, useRef } from 'react'
import {
  Box,
  Grid,
  GridSize,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { Help } from '@mui/icons-material'
import { useCritiqueStyles } from './styles'
import RichText, { BLOCKS } from '../RichText'
import { useWindowSize } from './../../hooks/useWindowDimension'
import { TFunction, useTranslation } from 'react-i18next'
import { CritiqueTRef } from './Translation'

type CritiqueRawDataListProp = {
  name: string
  info?: string
  variant?: string
  text?: any
  body?: string
}

const infoProperties = [
  {
    name: 'fullName',
    property: 'full_name',
  },
  {
    name: 'mostRecentEmpl',
    property: 'most_recent_employer',
    errorInfo: 'tooltipATSError',
  },
  {
    name: 'mostRecentPosit',
    property: 'most_recent_professional_position',
  },
  {
    name: 'contactInfo',
    body: (data: Record<string, unknown>) =>
      data?.phone_number && data?.email
        ? `${data?.phone_number}<br/>${data?.email}`
        : null,
  },
  {
    name: 'location',
    body: (data: Record<string, unknown>) =>
      data?.city && data?.region ? `${data?.city}, ${data?.region}` : null,
    errorInfo: 'tooltipEmptyLocation',
  },
  {
    name: 'experience',
    body: (
      data: Record<string, unknown>,
      t: TFunction<'translation', undefined>
    ) =>
      data?.experience_years
        ? `${data?.experience_years} ${t(
            `${CritiqueTRef.widgets}.applicantATS.totalYears`
          )}`
        : null,
  },
  {
    name: 'mostRecentEdu',
    property: 'most_recent_school',
  },
  {
    name: 'managementScore',
    body: (data: Record<string, unknown>) => `${data?.management_score}/100`,
    info: 'tooltipManagementScore',
  },
]

export const mapInfoGrid = (
  data: Record<string, unknown>,
  t: TFunction<'translation', undefined>
): CritiqueRawDataListProp[] => {
  return infoProperties.map(({ name, info, body, property, errorInfo }) => {
    const renderBody = body ? body(data, t) : (data?.[property] as string) || ''
    return {
      name,
      body: renderBody || '',
      ...(info && { info }),
      ...(!renderBody && {
        info: errorInfo || t(`${CritiqueTRef.widgets}.applicantATS.atsFailed`),
        variant: 'error',
        body: t(`${CritiqueTRef.widgets}.applicantATS.notFound`),
      }),
    }
  })
}

export const CritiqueInfoGrid: React.FC<{
  listItems?: CritiqueRawDataListProp[]
}> = ({ listItems }) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()
  const { classes } = useCritiqueStyles()

  const groupedItems = useMemo(() => {
    if (!listItems || !listItems) return null

    const parentWidth = parentRef.current?.offsetWidth

    const groupSize = parentWidth ? (parentWidth > 400 ? 2 : 1) : 2

    const groupedItems = []
    for (let i = 0; i < listItems.length; i += groupSize) {
      groupedItems.push(listItems.slice(i, i + groupSize))
    }
    return groupedItems
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems, width])

  return (
    <div ref={parentRef}>
      <Box className={classes.infoGridContainer}>
        <Grid container spacing={0}>
          {groupedItems &&
            groupedItems.map((row, index) => (
              <InfoGridRow
                key={index}
                items={row}
                lastRow={index === groupedItems.length - 1}
              />
            ))}
        </Grid>
      </Box>
    </div>
  )
}

const InfoGridRow: React.FC<{
  items: CritiqueRawDataListProp[]
  lastRow: boolean
}> = ({ items, lastRow }) => {
  return (
    <Grid item container xs={12} spacing={0}>
      {items.map((item, index) => (
        <InfoGridItem
          key={index}
          {...item}
          lastRow={lastRow}
          lastItem={index === items.length - 1}
          size={parseInt(12 / items.length + '') as GridSize}
        />
      ))}
    </Grid>
  )
}

const InfoGridItem: React.FC<
  CritiqueRawDataListProp & {
    lastRow: boolean
    lastItem: boolean
    size: GridSize
  }
> = (props) => {
  const { name, variant, text, info, size, lastRow, lastItem, body } = props
  const { classes, cx } = useCritiqueStyles()
  const { t } = useTranslation()

  return (
    <Grid item xs={size}>
      <Box
        height="100%"
        className={cx({
          [classes.infoGridItemBorderBottom]: !lastRow,
          [classes.infoGridItemBorderRight]: !lastItem,
          [classes.infoGridItemError]: variant === 'error',
        })}
      >
        <Box className={classes.infoGridItem}>
          <Box display="flex" flexDirection="column" gap="2px">
            <Typography
              variant="inherit"
              className={classes.infoGridItemHeader}
            >
              {t(`${CritiqueTRef.widgets}.rawDataProperties.${name}`) ||
                `${name}`}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              gap="4px"
              alignItems="flex-end"
            >
              {text && (
                <RichText
                  rawBody={text.raw}
                  references={text.references}
                  options={{
                    props: {
                      [BLOCKS.PARAGRAPH]: {
                        className: cx({
                          [classes.typographyCaption]: !variant,
                          [classes.infoGridTextError]: variant === 'error',
                        }),
                      },
                    },
                  }}
                />
              )}
              {body && (
                <Box display="flex" flexDirection="column">
                  <InfoGridText text={body} variant={variant} />
                </Box>
              )}
              {info && <InfoGridTextTooltip {...{ info }} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

const InfoGridText: React.FC<{ text: string; variant?: string }> = ({
  text,
  variant,
}) => {
  const { classes, cx } = useCritiqueStyles()

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="4px">
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{ __html: text }}
        className={cx({
          [classes.typographyCaption]: !variant,
          [classes.infoGridTextError]: variant === 'error',
        })}
      />
    </Box>
  )
}

const InfoGridTextTooltip: React.FC<{ info: string }> = ({ info }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box height="22px">
      <Tooltip
        title={t(`${CritiqueTRef.widgets}.rawDataProperties.${info}`) || info}
        placement="top"
        arrow
        classes={{
          tooltip: 'critique-tooltip',
          arrow: 'critique-tooltip-arrow',
        }}
      >
        <Help fontSize="small" htmlColor={theme.colors.blue['50']} />
      </Tooltip>
    </Box>
  )
}
