import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { useCritiqueStyles } from './styles'
import { CritiqueDataContext, ICritiqueProfile } from './useCritiqueCopy'

interface GradientScaleProps {
  header: string
  description: string
  colors?: string[]
  range?: number[]
  percentile: number
  unit?: string
  size?: number
  variant?: string
}

export const GradientScale: React.FC<GradientScaleProps> = ({
  header,
  description,
  colors = ['#EF4D5E', '#FFCF75', '#45C076'],
  range = [0, 100],
  percentile,
  size,
  unit = 'KB',
  variant = 'v1',
}) => {
  const {
    active: [, activeCritique],
  } = useContext(CritiqueDataContext) as { active: ICritiqueProfile }

  const { classes, cx } = useCritiqueStyles()

  const isV2 = variant === 'v2'

  const digitalReadinessSection = activeCritique?.full_sections?.find(
    ({ sections }: { sections: string }) => sections === 'digital-readiness'
  )?.Bodies[0]?.voice_body

  return (
    <>
      {isV2 && digitalReadinessSection && (
        <Typography
          variant="inherit"
          className={cx('critique-text-line', classes.typographyBody)}
          dangerouslySetInnerHTML={{
            __html: digitalReadinessSection,
          }}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        className={classes.gradientBarContainer}
      >
        <Box
          display="flex"
          className={classes.gradientBarHeader}
          justifyContent="space-between"
        >
          <Typography
            variant="inherit"
            className={cx(classes.typographyBody, classes.italic)}
          >
            {header}
          </Typography>
          <Box display="flex">
            {size && (
              <Typography
                variant="inherit"
                className={cx(classes.typographyBody, classes.bolder)}
              >
                {size}
              </Typography>
            )}
            {unit && (
              <Typography
                variant="inherit"
                className={cx(classes.typographyBody, classes.bolder)}
              >
                {unit}
              </Typography>
            )}
          </Box>
        </Box>
        <GradientBar colors={colors} range={range} value={percentile} />
        {description && (
          <Typography
            variant="inherit"
            className={cx(classes.typographyCaption, classes.neutral)}
          >
            {description}
          </Typography>
        )}
      </Box>
    </>
  )
}

const GradientBar: React.FC<{
  colors: string[]
  range: number[]
  value: number
}> = ({ colors, range, value }) => {
  const { classes, cx } = useCritiqueStyles()

  const size = 20

  const percentage = (value - range[0]) / (range[1] - range[0])
  const invertedPercentage = range[1] - percentage * 100

  const finalColor = interpolateColor(invertedPercentage, colors)

  return (
    <Box display="flex" height={size * 2} alignItems="center" mt="12px">
      <Box position="relative" width="100%">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.gradientBarNeedle}
          style={{
            width: size * 2,
            height: size * 2,
            right: `calc(${100 - percentage * 100}% - ${size}px)`,
            top: (size / 2) * -1,
          }}
        >
          <Box
            className={classes.gradientBarNeedleInner}
            style={{
              width: size,
              height: size,
              background: finalColor,
            }}
          />
        </Box>
        <Box
          className={classes.gradientBarBG}
          style={{
            height: `calc(${size}px + 2px)`,
            top: -1,
            width: invertedPercentage + '%',
          }}
        />
        <Box
          className={classes.gradientBar}
          style={{
            height: size,
            background: `linear-gradient(to left, ${colors.join(', ')})`,
          }}
        />
      </Box>
    </Box>
  )
}

const interpolateColor: (percentage: number, colors: string[]) => string = (
  percentage,
  colors
) => {
  const segmentLength = 100 / (colors.length - 1)
  const segmentIndex = Math.min(
    Math.floor(percentage / segmentLength),
    colors.length - 2
  )
  const segmentPercentage = (percentage % segmentLength) / segmentLength

  // Convert colors from hex to RGB if necessary
  const rgbColors = colors.map((color) => {
    if (typeof color === 'string') {
      // Assuming the color is in hex format
      const r = parseInt(color.slice(1, 3), 16)
      const g = parseInt(color.slice(3, 5), 16)
      const b = parseInt(color.slice(5, 7), 16)
      return [r, g, b]
    }
    return color
  })

  const startColor = rgbColors[segmentIndex]
  const endColor = rgbColors[segmentIndex + 1]

  // Linearly interpolate each component
  const r = startColor[0] + (endColor[0] - startColor[0]) * segmentPercentage
  const g = startColor[1] + (endColor[1] - startColor[1]) * segmentPercentage
  const b = startColor[2] + (endColor[2] - startColor[2]) * segmentPercentage

  return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`
}
