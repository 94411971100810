import React, { useMemo } from 'react'
import { Button } from '@mui/material'
import { ActionLinkPost, Button as ButtonType } from 'types/page'
import { useCritiqueStyles } from './styles'
import { interpolateString } from '../../utils/interpolateString'
import useBrand from '../../hooks/useBrand'
import { useMixpanelCTAClickTracking } from '../../utils/mixpanel'
import useTidyLink from '../../hooks/useTidyLink'
import { kebabCase } from 'lodash'

export const CritiqueButton: React.FC<ButtonType<ActionLinkPost>> = (props) => {
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const tidyTheLink = useTidyLink()

  const interpolatedSlug = useMemo(
    () =>
      props?.action?.slug
        ? interpolateString(props?.action?.slug, {
            ...brand,
          }).toLowerCase()
        : '',
    [props?.action?.slug, brand]
  )

  return (
    <Button
      data-testid={kebabCase(`cta ${props.buttonText}`)}
      classes={{
        root: props.codeClasses?.join(' '),
      }}
      onAuxClick={(e) => {
        handleMpCTAClickTracking(
          e.currentTarget.textContent,
          tidyTheLink(interpolatedSlug),
          props.mixpanelTracking
        )
      }}
      onClick={(e) => {
        handleMpCTAClickTracking(
          e.currentTarget.textContent,
          tidyTheLink(interpolatedSlug),
          props.mixpanelTracking
        )
      }}
      className={cx({
        [classes.mainButtonSmall]: props.codeClasses?.includes('linkSmall'),
        [classes.marginTop8]: props.codeClasses?.includes('marginTop8'),
      })}
      // TODO: REMOVE THIS WHEN /NEW NO LONGER APPLICABLE
      {...(props?.action.slug && {
        href: `../${interpolatedSlug}`,
        target: '_blank',
      })}
    >
      {props.buttonText}
    </Button>
  )
}
