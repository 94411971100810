export const interpolateString = (
  template: string,
  variables: Record<string, any>
) => {
  const helpers = {
    uppercase: (value: string) => value.toUpperCase(),
    lowercase: (value: string) => value.toLowerCase(),
    pluralize: (value: string) => (value.endsWith('s') ? value : value + 's'),
    capitalize: (value: string) =>
      value.charAt(0).toUpperCase() + value.slice(1),
    titlecase: (value: string) =>
      value
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
  }

  return template?.replace
    ? template?.replace(
        /\{\{\s*(\w+)?\s*(?:\|\s*(\w+)\s*)?\}\}/g,
        (match, keyOrHelper, key) => {
          let value
          let helper

          if (key) {
            helper = helpers[keyOrHelper as keyof typeof helpers]
            value = variables[key]
          } else {
            key = keyOrHelper
            value = variables[key]
          }

          if (helper && value !== undefined) {
            return helper(value)
          } else if (value !== undefined) {
            return value
          }
          return match
        }
      ) || template
    : template;
}
